import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LiftsSearchRepository {

    baseUrl = 'lifts/search';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post(`${this.baseUrl}`, criteria);
    }

}
