import { bindable, inject, observable } from 'aurelia-framework';
import { AppContainer }                 from 'resources/services/app-container';
import { BaseListViewModel }            from 'base-list-view-model';
import { DialogService }                from 'aurelia-dialog';
import { LiftsSearchRepository }        from 'modules/lifts/search/services/repository';
import { ViewLiftCertificates }         from 'modules/lifts/lifts/certificates/index';
import { ViewLiftChecklist }            from 'modules/lifts/lifts/checklists/index';
import { ViewLiftHistory }              from 'modules/lifts/lifts/history/index';

@inject(AppContainer, LiftsSearchRepository, DialogService)
export class ListLiftSearch extends BaseListViewModel {

    listingId = 'lifts-search-listing';

    @bindable headerTitle = 'listing.lifts.search';
    @bindable repository;
    @bindable datatable;

    @observable address = '';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     */
    constructor(appContainer, repository, dialogService) {
        super(appContainer);

        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.address = decodeURIComponent(params.address);

        this.defineDatatable();
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = '"' + this.address + '"';
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.lifts.lift',
            repository:      {
                search: this.search.bind(this),
            },
            actions:         [
                {
                    icon:    'icon-stack-check',
                    tooltip: 'form.button.checklist',
                    action:  (row) => this.openLiftChecklist(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
                },
                {
                    icon:    'icon-history',
                    tooltip: 'form.button.history',
                    action:  (row) => this.openLiftHistory(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
                },
                {
                    icon:    'icon-certificate',
                    tooltip: 'form.button.certificates',
                    action:  (row) => this.openLiftCertificates(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
                },
            ],
            options:         [],
            buttons:         [],
            destroySelected: false,
            selectable:      false,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: row.id }),
                },
                {
                    data:  'lift_type_name',
                    name:  'lift_types.name',
                    title: 'form.field.lift-type',
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish_name',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'current_owner_name',
                    name:  'owner_data.name',
                    title: 'form.field.owner/administrator',
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     */
    search(criteria) {
        criteria.address = this.address;

        return this.repository.search(criteria);
    }

    /**
     * Opens the lift checklist modal
     *
     * @param row
     */
    openLiftChecklist(row) {
        this.dialogService.open({ viewModel: ViewLiftChecklist, model: row });
    }

    /**
     * Opens the lift history modal
     *
     * @param row
     */
    openLiftHistory(row) {
        this.dialogService.open({ viewModel: ViewLiftHistory, model: row });
    }

    /**
     * Opens the lift certificates modal
     *
     * @param row
     */
    openLiftCertificates(row) {
        this.dialogService.open({ viewModel: ViewLiftCertificates, model: row });
    }

}
