import { inject }                         from 'aurelia-framework';
import { BaseFilterFormSchema }           from 'resources/classes/base-filter-form-schema';
import { CountiesRepository }             from 'modules/administration/locations/counties/services/repository';
import { DialogService }                  from 'aurelia-dialog';
import { DistrictsRepository }            from 'modules/administration/locations/districts/services/repository';
import { EventAggregator }                from 'aurelia-event-aggregator';
import { InspectingEntitiesRepository }   from 'modules/entities/inspecting-entities/services/repository';
import { InstallingCompaniesRepository }  from 'modules/entities/installing-companies/services/repository';
import { I18N }                           from 'aurelia-i18n';
import { LiftTypesRepository }            from 'modules/management/lift-types/services/repository';
import { LocalStorage }                   from 'resources/services/local-storage';
import { MaintenanceCompaniesRepository } from 'modules/entities/maintenance-companies/services/repository';
import { OwnersRepository }               from 'modules/entities/owners/services/repository';
import { ParishesRepository }             from 'modules/administration/locations/parishes/services/repository';

@inject(LocalStorage, EventAggregator, I18N, DialogService, DistrictsRepository, CountiesRepository, InspectingEntitiesRepository, InstallingCompaniesRepository, LiftTypesRepository, MaintenanceCompaniesRepository, OwnersRepository, ParishesRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        code:                  null,
        lift_types:            [],
        installing_companies:  [],
        owners:                [],
        maintenance_companies: [],
        districts:             [],
        counties:              [],
        parishes:              [],
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     * @param districtsRepository
     * @param countiesRepository
     * @param inspectingEntitiesRepository
     * @param installingCompaniesRepository
     * @param liftTypesRepository
     * @param maintenanceCompaniesRepository
     * @param ownersRepository
     * @param parishesRepository
     */
    constructor(storage, eventAggregator, i18n, dialogService, districtsRepository, countiesRepository, inspectingEntitiesRepository, installingCompaniesRepository, liftTypesRepository, maintenanceCompaniesRepository, ownersRepository, parishesRepository) {
        super(storage, eventAggregator, i18n, dialogService);

        this.districtsRepository            = districtsRepository;
        this.countiesRepository             = countiesRepository;
        this.inspectingEntitiesRepository   = inspectingEntitiesRepository;
        this.installingCompaniesRepository  = installingCompaniesRepository;
        this.liftTypesRepository            = liftTypesRepository;
        this.maintenanceCompaniesRepository = maintenanceCompaniesRepository;
        this.ownersRepository               = ownersRepository;
        this.parishesRepository             = parishesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.code = {
            type:     'text',
            key:      'code',
            label:    'form.field.lift-number',
            size:     4,
            required: false,
        };

        this.lift_types = {
            type:         'multiselect-native',
            key:          'lift_types',
            label:        'form.field.lift-types',
            size:         4,
            remoteSource: this.liftTypesRepository.all.bind(this.liftTypesRepository),
            required:     false,
        };

        this.owners = {
            type:         'multiselect-native',
            key:          'owners',
            label:        'form.field.owners/administrators',
            size:         4,
            remoteSource: this.ownersRepository.all.bind(this.ownersRepository),
            required:     false,
        };

        this.installing_companies = {
            type:         'multiselect-native',
            key:          'installing_companies',
            label:        'form.field.installing-companies',
            size:         4,
            remoteSource: this.installingCompaniesRepository.all.bind(this.installingCompaniesRepository),
            required:     false,
        };

        this.maintenance_companies = {
            type:         'multiselect-native',
            key:          'maintenance_companies',
            label:        'form.field.maintenance-companies',
            size:         4,
            remoteSource: this.maintenanceCompaniesRepository.all.bind(this.maintenanceCompaniesRepository),
            required:     false,
        };

        this.districts = {
            type:           'multiselect-native',
            key:            'districts',
            label:          'form.field.districts',
            size:           4,
            remoteSource:   this.districtsRepository.all.bind(this.districtsRepository),
            processResults: (row) => this.processLocationResults(row),
            required:       false,
            observers:      [
                () => this.handleDistrictsChanged(viewModel),
            ],
        };

        this.counties = {
            type:                   'multiselect-native',
            key:                    'counties',
            label:                  'form.field.counties',
            size:                   4,
            remoteSource:           (params) => viewModel.filterModel.districts.length ? this.countiesRepository.allByDistricts(params) : Promise.resolve([]),
            remoteSourceParameters: () => viewModel.filterModel.districts,
            processResults:         (row) => this.processLocationResults(row),
            required:               false,
            attributes:             {
                disabled: !viewModel.filterModel.districts.length,
            },
            observers:              [
                () => this.handleCountiesChanged(viewModel),
            ],
        };

        this.parishes = {
            type:                   'multiselect-native',
            key:                    'parishes',
            label:                  'form.field.parishes',
            size:                   4,
            remoteSource:           (params) => viewModel.filterModel.counties.length ? this.parishesRepository.allByCounties(params) : Promise.resolve([]),
            remoteSourceParameters: () => viewModel.filterModel.counties,
            processResults:         (row) => this.processLocationResults(row),
            required:               false,
            attributes:             {
                disabled: !viewModel.filterModel.counties.length,
            },
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', { listingId: viewModel.listingId, criteria: viewModel.filterModel }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.code, this.lift_types],
            [this.owners, this.installing_companies, this.maintenance_companies],
            [this.districts, this.counties, this.parishes],
            [this.buttons],
        ];
    }

    /**
     * Processes the location fields results
     *
     * @param row
     */
    processLocationResults(row) {
        row.name = `${row.code} - ${row.name}`;

        return row;
    }

    /**
     * Handles the `districts` field changes
     *
     * @param viewModel
     */
    handleDistrictsChanged(viewModel) {
        if (viewModel.filterModel.districts.length) {
            this.counties.instance.fetchData().then(() => this.counties.instance.enable());
        } else {
            this.counties.instance.disable();
        }
    }

    /**
     * Handles the `counties` field changes
     *
     * @param viewModel
     */
    handleCountiesChanged(viewModel) {
        if (viewModel.filterModel.counties.length) {
            this.parishes.instance.fetchData().then(() => this.parishes.instance.enable());
        } else {
            this.parishes.instance.disable();
        }
    }

}
